"use strict";

var cookiePdfName = 'pdf_pages';
var cookiePdf     = $.cookie(cookiePdfName) == null ? cookiePdf = '' : $.cookie(cookiePdfName);
var optionsCtx    = 'nav.download';
var selectionCtx  = '.myreport';
var addLink       = '.add-to-pdf';
var pdfUrl        = '/pdfondemand/printpdf?docId=' + $(optionsCtx).attr('data-documentid');
var nodeInfoAttr  = 'node-info';
var thisNodeInfo  = $(optionsCtx).data(nodeInfoAttr);

// function splitNodeInfo(value) {
  // var array = value.split('|');
  // return {index: array[0], id: array[1], title: array[2], url: array[3]};
// }

function editCookie(nodeInfo,action) {
  if (action=='add') cookiePdf += nodeInfo + ';';
  if (action=='rem') cookiePdf = cookiePdf.replace(nodeInfo + ';','');
  $.cookie(cookiePdfName,cookiePdf,{expires:7,path:'/'});
}

function setCount() {
  var count = cookiePdf.split(';').length - 1;
  $('.pdf-pages-count',optionsCtx).text(count);
}

function buildPagesList() {
  var nodes = cookiePdf.split(';').sort();
  var list  = '';
  for(var i=1; i<nodes.length; i++) {
    var nodeInfo = splitNodeInfo(nodes[i]);
    list += '<li><a class="rem"></a><a href="'+nodeInfo.url+'" data-node-info="'+nodes[i]+'">'+nodeInfo.title+'</a></li>';
  }
  if (list!='') $('h3',selectionCtx).after('<ul>'+list+'</ul>');
}

function downloadPdf(mode) {
  var url = pdfUrl + (mode=='eco' ? '&variant=eco' : '') + '&nodes=';
  var nodes = cookiePdf.substr(0,cookiePdf.length-1).split(';');
  for(var i=0; i<nodes.length; i++) {
    var nodeInfo = splitNodeInfo(nodes[i]);
    url += nodeInfo.id + '-';
  }
  location.href = url;
}

setCount();
if (cookiePdf.indexOf(thisNodeInfo) != -1) $(addLink).addClass('added');
if ($(selectionCtx).length==1) buildPagesList();

$(addLink).not('.disabled').click(function(){
  if (cookiePdf.indexOf(thisNodeInfo) == -1) {
	  editCookie(thisNodeInfo,'add');
	  $(this).addClass('added');
  }
  else {
	  editCookie(thisNodeInfo,'rem');
	  $(this).removeClass('added');
  }
  setCount();
});

$('.rem',selectionCtx).click(function(){
  var nodeInfo = $(this).next().data(nodeInfoAttr);
  editCookie(nodeInfo,'rem');
  if ($(this).parent().siblings().length==0) $(this).parents('ul').remove();
  else $(this).parent().remove();
});